import { Col, Row } from "antd";
import { HTMLAttributes } from "react";
import { Carousel } from "react-bootstrap";
import { CompanyCard } from "../../components";
import { ScreenSize, useMedia } from "../../hooks";
import { AboutSectionCarousel, PartnerContainer } from "./styles/about-section";
const Audience = require("../../assets/images/audience.webp");
const MCITLogo = require("../../assets/images/mcit-logo.png");
const CODELogo = require("../../assets/images/CODE.png");
const IEEELogo = require("../../assets/images/ieee.png");
const GenesysLogo = require("../../assets/images/genesys.png");
const TqaddamLogo = require("../../assets/images/twaddam.webp");
const PlugPlayLogo = require("../../assets/images/plug_play.png");
const TechstarsLogo = require("../../assets/images/techstars.png");
const ProductTankLogo = require("../../assets/images/product_tank.png");
const AlgorizaLogo = require("../../assets/images/algoriza.jpg");
const Gallery1 = require("../../assets/images/gallery/gallery1.webp");
const Gallery2 = require("../../assets/images/gallery/gallery2.webp");
const Gallery3 = require("../../assets/images/gallery/gallery3.webp");
const Gallery4 = require("../../assets/images/gallery/gallery4.webp");
const Gallery5 = require("../../assets/images/gallery/gallery5.webp");
const Gallery6 = require("../../assets/images/gallery/gallery6.webp");
const Gallery7 = require("../../assets/images/gallery/gallery7.webp");
const Gallery8 = require("../../assets/images/gallery/gallery8.webp");
const Gallery9 = require("../../assets/images/gallery/gallery9.webp");

const galleryImages = [Gallery1, Gallery2, Gallery3, Gallery4, Gallery5, Gallery6, Gallery7, Gallery8, Gallery9];

export const AboutSection: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
    const aboveSM = useMedia(ScreenSize.SM);
    return (
        <section id="about" style={{ width: "100%" }}>
            <Row
                gutter={24}
                className="d-flex align-items-center"
                style={{ backgroundColor: "#000", maxHeight: "1270px" }}
            >
                <Row className="w-100 mb-5">
                    <img style={{ width: "100%" }} loading="lazy" alt="Audience" src={Audience} />
                </Row>
                <Row className="d-flex justify-content-center justify-content-md-around w-100">
                    <h2 className="mb-4">About JobStack</h2>
                    <div>
                        <ul style={{ lineHeight: 2.5, width: "96%" }}>
                            The largest Tech Community in Middle East & Africa where tech gurus,
                            <br /> entrepreneurs, Investors, and enterprises meet, get your ticket to enjoy:
                            <li>1- Navigating different employer booths, drop off your CV, and apply for vacancies.</li>
                            <li>2- Attending talks about the latest trends in the software industry.</li>
                            <li>3- Networking with top regional technical gurus.</li>
                        </ul>
                    </div>
                </Row>
                <Row>
                    <Col xs={24}>
                        <div style={{ width: "100%", minWidth: "100vw" }} className=" d-flex justify-content-center">
                            <AboutSectionCarousel defaultActiveIndex={0} interval={2000}>
                                {galleryImages.map((image) => (
                                    <Carousel.Item key={image}>
                                        <img src={image} alt="First slide" style={{ objectFit: "contain" }} />
                                    </Carousel.Item>
                                ))}
                            </AboutSectionCarousel>
                        </div>
                    </Col>
                </Row>
            </Row>
            <PartnerContainer gutter={24} className="d-flex align-items-center mt-0 mt-md-5">
                <Col xs={24}>
                    <Row className="mb-0 mb-md-5">
                        <Col xs={24}>
                            <h2 style={{ color: "#000" }} className="mx-3">
                                Strategic Partners
                            </h2>
                            <p style={{ color: "#000", fontWeight: 500, fontSize: "0.9rem" }} className="mx-3">
                                Elevating Tech Innovation Across the MEA Region
                            </p>
                        </Col>
                    </Row>
                    <Row gutter={16} className="d-flex justify-content-center">
                        <Col xs={12}>
                            <CompanyCard imgSource={MCITLogo} minWidth={aboveSM ? undefined : "200px"} />
                        </Col>
                        <Col xs={11} className="mb-5 mb-md-0">
                            <CompanyCard imgSource={CODELogo} minWidth={aboveSM ? undefined : "130px"} />
                        </Col>
                    </Row>
                </Col>
            </PartnerContainer>
            <PartnerContainer gutter={24} className="d-flex align-items-center">
                <Col xs={24}>
                    <Row className="mb-0 mb-md-5">
                        <Col xs={24}>
                            <h2 style={{ color: "#000" }} className="mx-3">
                                Technology Partners
                            </h2>
                        </Col>
                    </Row>
                    <Row gutter={16} className="d-flex justify-content-center">
                        <Col xs={12}>
                            <CompanyCard imgSource={IEEELogo} minWidth={aboveSM ? undefined : "200px"} />
                        </Col>
                        <Col xs={10} className="mb-5 mb-md-0">
                            <CompanyCard imgSource={GenesysLogo} minWidth={aboveSM ? undefined : "130px"} />
                        </Col>
                    </Row>
                </Col>
            </PartnerContainer>
            <PartnerContainer gutter={24} className="d-flex align-items-center">
                <Col xs={24}>
                    <Row className="mb-0 mb-md-5">
                        <Col xs={24}>
                            <h2 style={{ color: "#000" }} className="mx-3">
                                Ecosystem Partners
                            </h2>
                        </Col>
                    </Row>
                    <Row gutter={16} className="d-flex justify-content-center">
                        <Col xs={13} md={8} lg={4}>
                            <CompanyCard imgSource={TqaddamLogo} minWidth={aboveSM ? undefined : "200px"} />
                        </Col>
                        <Col xs={12} md={8} lg={4}>
                            <CompanyCard imgSource={PlugPlayLogo} minWidth={aboveSM ? undefined : "130px"} />
                        </Col>
                        <Col xs={12} md={8} lg={4}>
                            <CompanyCard imgSource={TechstarsLogo} minWidth={aboveSM ? undefined : "130px"} />
                        </Col>
                        <Col xs={12} md={8} lg={4}>
                            <CompanyCard imgSource={ProductTankLogo} minWidth={aboveSM ? undefined : "130px"} />
                        </Col>
                        <Col xs={12} md={8} lg={4}>
                            <CompanyCard imgSource={AlgorizaLogo} minWidth={aboveSM ? undefined : "130px"} />
                        </Col>
                    </Row>
                </Col>
            </PartnerContainer>
        </section>
    );
};

import { useMutation } from "@tanstack/react-query";
import { Col, Form, Input, Row, Select, message } from "antd";
import { HTMLAttributes, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { ButtonType, TAButton } from "../../components";
import { DraggableUpload } from "../../components/draggable-upload";
import { transformToFormData } from "../../utils";
import configProxy from "../../utils/config";
import { TicketSectionContainer, TicketSectionForm } from "./styles/ticket-section";
import jobstackService from "../../services/jobstack.service";
import { AxiosError } from "axios";

const ColorPattern = require("../../assets/images/color-pattern-3.png");

export const TicketSection: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
    const [form] = Form.useForm();
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const ticketRequestMutation = useMutation({
        mutationFn: (ticketRequest) => jobstackService.createTicket(ticketRequest, recaptchaToken),
        onSuccess: (data) => {
            message.success("Your ticket is submitted successfully");
            form.resetFields();
            setRecaptchaToken(null);
            recaptchaRef.current?.reset();
        },
        onError: (e: AxiosError<{ message: string }>) => {
            form.resetFields();
            setRecaptchaToken(null);
            recaptchaRef.current?.reset();
            message.error(e?.response?.data?.message);
        }
    });

    return (
        <TicketSectionContainer id="tickets">
            <Row gutter={24} className="d-flex justify-content-between">
                <Col xs={24} lg={0} className="mb-5">
                    <h2 className="mb-3">Register to get your free ticket</h2>
                    <p className="mb-3">Join the Software Industry Summit.</p>
                    <p>
                        Claim your ticket to JobStack_ 2023 virtual event now. Your ticket will be reviewed shortly
                        after your submission, stay tuned.
                    </p>
                </Col>
                <div style={{ position: "absolute", right: "10%", bottom: 0 }}>
                    <img loading="lazy" alt="pattern" src={ColorPattern} />
                </div>
                <Col xs={24} lg={10} xl={12} className="mb-5 mb-lg-0">
                    <TicketSectionForm
                        layout="vertical"
                        form={form}
                        onFinish={(values: any) => {
                            if (!recaptchaToken) return;

                            const fd = transformToFormData(values);

                            ticketRequestMutation.mutate(fd as any);
                        }}
                    >
                        <Row gutter={12} className="d-flex justify-content-end">
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="firstName"
                                    label="First Name"
                                    rules={[{ required: true, message: "First name is required", whitespace: true }]}
                                >
                                    <Input placeholder="First name" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="lastName"
                                    label="Last Name"
                                    rules={[{ required: true, message: "Last name is required", whitespace: true }]}
                                >
                                    <Input placeholder="Last name" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12} className="d-flex justify-content-end">
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        { required: true, message: "Email is required" },
                                        { type: "email", message: "invalid email format" }
                                    ]}
                                >
                                    <Input placeholder="xyz@abc.com" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="linkedin"
                                    label="Linkedin"
                                    rules={[
                                        { required: true, message: "Linkedin uri is required" },
                                        {
                                            validator: (_, value) => {
                                                if (
                                                    !value ||
                                                    /^https?:\/\/(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/.test(
                                                        value
                                                    )
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        "Invalid LinkedIn URL format, make sure it starts with https://www.linkedin.com/in/"
                                                    )
                                                );
                                            }
                                        }
                                    ]}
                                >
                                    <Input placeholder="https://www.linkedin.com/in/your-handle/" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12} className="d-flex justify-content-end">
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Job Title"
                                    name="jobTitle"
                                    rules={[{ required: true, message: "job title is required", whitespace: true }]}
                                >
                                    <Input placeholder="e.g. software engineer or student" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="companyName"
                                    label="Company Name"
                                    rules={[{ required: true, message: "company name is required", whitespace: true }]}
                                >
                                    <Input placeholder="college name if student" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12} className="">
                            <Col xs={24} sm={12}>
                                <Form.Item name="phoneNumber" label="Phone Number">
                                    <Input placeholder="+1 123-456-7890" />
                                </Form.Item>
                            </Col>
                            {/* <Col xs={12}>
                                <Form.Item name="gender" label="Gender" initialValue={undefined}>
                                    <Select placeholder="gender">
                                        <Select.Option value="male">Male</Select.Option>
                                        <Select.Option value="female">Female</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col> */}
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Col xs={24} lg={18}>
                                <Form.Item name="cv">
                                    <DraggableUpload
                                        onFileUpload={(file) => {
                                            form.setFieldsValue({ cv: file });
                                        }}
                                        name="cv"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-center">
                            <ReCAPTCHA
                                theme="light"
                                sitekey={configProxy.reCaptchaSiteKey}
                                ref={recaptchaRef}
                                onChange={setRecaptchaToken}
                            />
                        </div>
                        <TAButton
                            disabled={!recaptchaToken}
                            btnType={ButtonType.PRIMARY}
                            htmlType="submit"
                            colorTheme="#000"
                            className="mt-4"
                            textColor="#fff"
                            loading={ticketRequestMutation.isLoading}
                        >
                            <span style={{ color: "#fff" }}>Submit</span>
                        </TAButton>
                    </TicketSectionForm>
                </Col>
                <Col xs={0} md={0} lg={12}>
                    <h2 className="mb-3">Register to get your free ticket</h2>
                    <p className="mb-3">Join the Software Industry Summit.</p>
                    <p>
                        Claim your ticket to JobStack_ 2023 virtual event now. Your ticket will be reviewed shortly
                        after your submission, stay tuned.
                    </p>
                </Col>
                <div style={{ position: "absolute", right: "10%", bottom: 0 }}>
                    <img loading="lazy" alt="pattern" src={ColorPattern} />
                </div>
            </Row>
        </TicketSectionContainer>
    );
};

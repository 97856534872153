import { Col, Row } from "antd";
import { HTMLAttributes } from "react";
import { ButtonSize, ButtonType, JobStackLogo, TAButton } from "../components";
import { Colors } from "../constants";
const WelcomeBanner = require("../assets/images/welcome.png");
const ColorPattern = require("../assets/images/color-pattern.png");

export const WelcomeSection: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
    return (
        <section id="welcome" style={{ color: "#000", width: "100%" }}>
            <Row gutter={24} className="d-flex align-items-center" style={{ backgroundColor: "#000" }}>
                <Col xs={22} md={9} lg={8} xl={6} xxl={6}>
                    <img loading="lazy" alt="Welcome banner" src={WelcomeBanner} style={{ width: "110%" }} />
                </Col>
                <Col lg={1} xl={1} xxl={1}></Col>
                <Col xs={24} md={11} lg={15} xl={17} xxl={16}>
                    <Row>
                        <Col className="p-4 p-md-0 mb-0 mb-md-3" xs={24} lg={11} xl={11} xxl={12}>
                            <div className="d-flex flex-column">
                                <JobStackLogo width="280px" className="mb-5" />
                                <h2 className="mb-3 mt-5 mt-md-0" style={{ color: "#fff" }}>
                                    Software Industry Summit
                                </h2>
                                <p>Forming the Future of Technology Excellence and Innovation in the MEA Region.</p>
                            </div>
                        </Col>
                        <Col xs={0} md={0} lg={0} xl={2} xxl={1}></Col>
                        <Col className="p-4 p-md-0" xs={24} lg={11} xl={11} xxl={10}>
                            <h1 className="mb-3" style={{ color: Colors.TA_ORANGE }}>
                                16-18 NOV
                            </h1>
                            <h3 className="mb-3" style={{ color: "#fff", fontWeight: 600 }}>
                                Riyadh, Saudi Arabia & Virtual{" "}
                            </h3>
                            {/* <h5 style={{ color: Colors.TA_ORANGE }}>Tickets will be available soon...</h5> */}
                            {/* <a href="#tickets">
                                <TAButton
                                    btnType={ButtonType.PRIMARY}
                                    style={{ color: "#fff", fontWeight: 600, fontSize: "1rem" }}
                                    colorTheme={Colors.TA_ORANGE}
                                    width="100%"
                                >
                                    Get your free ticket
                                </TAButton>
                            </a> */}
                            {/* <a
                                href="https://docs.google.com/forms/d/e/1FAIpQLSeaUsvbJwjwtF38amWC_xrdUNd2sI6NVTrskDsuDCg8FkbLNQ/viewform"
                                target="_blank"
                            >
                                <TAButton
                                    btnType={ButtonType.PRIMARY}
                                    style={{ color: "#fff", fontWeight: 600, fontSize: "1rem" }}
                                    colorTheme={Colors.TA_ORANGE}
                                    width="100%"
                                >
                                    Join As Speaker
                                </TAButton>
                            </a> */}
                            {/* <a
                                href="https://www.airmeet.com/e/35ca1ff0-640f-11ee-9bf7-35f05d1ecc42"
                                target="_blank"
                                className="d-inline-block w-100 mt-3"
                            >
                                <TAButton
                                    btnType={ButtonType.PRIMARY}
                                    style={{ color: Colors.TA_ORANGE, fontWeight: 600, fontSize: "1rem" }}
                                    colorTheme={"#fff"}
                                    width="100%"
                                >
                                    Check the Agenda
                                </TAButton>
                            </a> */}
                        </Col>
                    </Row>
                </Col>
                {/* <div style={{ position: "absolute", right: 0 }} className="d-none d-md-block">
                    <img loading="lazy" alt="pattern" src={ColorPattern} />
                </div> */}
            </Row>
        </section>
    );
};

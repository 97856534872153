import { Col, Row } from "antd";
import { HTMLAttributes } from "react";
import { CTASectionContainer } from "./styles/cta-section-container";
const MobilePattern = require("../../assets/images/CTA.png");
const PlayStoreLogo = require("../../assets/images/google-button.png");
const AppleStoreLogo = require("../../assets/images/app-store-button.png");

export const CTASection: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
    return (
        <CTASectionContainer id="cta">
            <Row gutter={24} className="d-flex align-items-center">
                <Col xs={24} className="mb-5">
                    <h2 className="mb-5" style={{ color: "#fff", lineHeight: 2 }}>
                        Live & Virtual Event <br /> No Matter Where You Are
                    </h2>
                    <div className="d-flex">
                        <a href="https://apps.apple.com/us/app/jobstack-2023/id6468648124" target="_blank">
                            <img src={AppleStoreLogo} alt="App store" loading="lazy" className="me-2" />
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.airmeet.talentsarena"
                            target="_blank"
                        >
                            <img src={PlayStoreLogo} alt="play store" loading="lazy" />
                        </a>
                    </div>
                </Col>
            </Row>

            <div style={{ position: "absolute", right: 0, bottom: 0 }} className="d-none d-lg-block">
                <img style={{ width: "90%" }} src={MobilePattern} alt="Color pattern" loading="lazy" />
            </div>
        </CTASectionContainer>
    );
};
